import {Link} from 'react-router-dom';
import {Icons} from 'ui-kit/Icons';
import './styles.css';

export function LandingHeader() {
  return (
    <header className="w-full p-5 header">
      <div className="header-nav">
        <Link
          to="/"
          className="flex items-center gap-1.5 font-semibold text-lg leading-none text-white"
        >
          <Icons.Logo />
          ponzi.market
        </Link>
        <Link
          to="https://medium.com/@rochgaspard/new-op-crypto-strategy-consciously-joining-ponzi-schemes-2f6d0012798b?source=friends_link&sk=90987e5d082d38055cb738060e84af67"
          className="opacity-60 text-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          Founder's Letter
        </Link>
      </div>
    </header>
  );
}
