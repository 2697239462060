import { SVGAttributes } from 'react';

export const Icons = {
  Menu: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="11"
      viewBox="0 0 20 11"
      fill="none"
    >
      <line y1="0.5" x2="20" y2="0.5" stroke="white" />
      <line y1="10.5" x2="20" y2="10.5" stroke="white" />
    </svg>
  ),
  ArrowRight: () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.8335 8H13.1668"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 3.33333L13.1667 8L8.5 12.6667"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Users: () => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 10.5V9.5C8.5 8.96957 8.28929 8.46086 7.91421 8.08579C7.53914 7.71071 7.03043 7.5 6.5 7.5H2.5C1.96957 7.5 1.46086 7.71071 1.08579 8.08579C0.710714 8.46086 0.5 8.96957 0.5 9.5V10.5"
        stroke="#CECECE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 5.5C5.60457 5.5 6.5 4.60457 6.5 3.5C6.5 2.39543 5.60457 1.5 4.5 1.5C3.39543 1.5 2.5 2.39543 2.5 3.5C2.5 4.60457 3.39543 5.5 4.5 5.5Z"
        stroke="#CECECE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 10.4999V9.49994C11.4997 9.05681 11.3522 8.62633 11.0807 8.2761C10.8092 7.92587 10.4291 7.67573 10 7.56494"
        stroke="#CECECE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.56494C8.43021 1.67509 8.81152 1.92529 9.08382 2.2761C9.35612 2.6269 9.50392 3.05836 9.50392 3.50244C9.50392 3.94653 9.35612 4.37798 9.08382 4.72879C8.81152 5.07959 8.43021 5.32979 8 5.43994"
        stroke="#CECECE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Telegram: ({color = 'white', ...props}: SVGAttributes<SVGElement>) => (
  <svg
    width="16" 
    height="16"
    viewBox="0 0 24 24" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.665 3.717l-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42l10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701l-.335 5.053c.492 0 .708-.226.985-.494l2.366-2.307l4.916 3.633c.906.5 1.558.242 1.784-.84l3.234-15.25c.332-1.328-.51-1.93-1.428-1.534z"
      fill={color}
    />
  </svg>
),
  Discord: ({color = 'white', ...props}: SVGAttributes<SVGElement>) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.59188 6.71191C6.13588 6.71191 5.77588 7.11191 5.77588 7.59991C5.77588 8.08791 6.14388 8.48791 6.59188 8.48791C7.04788 8.48791 7.40788 8.08791 7.40788 7.59991C7.41588 7.11191 7.04788 6.71191 6.59188 6.71191ZM9.51188 6.71191C9.05588 6.71191 8.69588 7.11191 8.69588 7.59991C8.69588 8.08791 9.06388 8.48791 9.51188 8.48791C9.96788 8.48791 10.3279 8.08791 10.3279 7.59991C10.3279 7.11191 9.96788 6.71191 9.51188 6.71191Z"
        fill={color}
      />
      <path
        d="M13.4 0H2.68004C1.77604 0 1.04004 0.736 1.04004 1.648V12.464C1.04004 13.376 1.77604 14.112 2.68004 14.112H11.752L11.328 12.632L12.352 13.584L13.32 14.48L15.04 16V1.648C15.04 0.736 14.304 0 13.4 0ZM10.312 10.448C10.312 10.448 10.024 10.104 9.78404 9.8C10.832 9.504 11.232 8.848 11.232 8.848C10.904 9.064 10.592 9.216 10.312 9.32C9.91204 9.488 9.52804 9.6 9.15204 9.664C8.38404 9.808 7.68004 9.768 7.08004 9.656C6.62404 9.568 6.23204 9.44 5.90404 9.312C5.72004 9.24 5.52004 9.152 5.32004 9.04C5.29604 9.024 5.27204 9.016 5.24804 9C5.23204 8.992 5.22404 8.984 5.21604 8.976C5.07204 8.896 4.99204 8.84 4.99204 8.84C4.99204 8.84 5.37604 9.48 6.39204 9.784C6.15204 10.088 5.85604 10.448 5.85604 10.448C4.08804 10.392 3.41604 9.232 3.41604 9.232C3.41604 6.656 4.56804 4.568 4.56804 4.568C5.72004 3.704 6.81604 3.728 6.81604 3.728L6.89604 3.824C5.45604 4.24 4.79204 4.872 4.79204 4.872C4.79204 4.872 4.96804 4.776 5.26404 4.64C6.12004 4.264 6.80004 4.16 7.08004 4.136C7.12804 4.128 7.16804 4.12 7.21604 4.12C7.70404 4.056 8.25604 4.04 8.83204 4.104C9.59204 4.192 10.408 4.416 11.24 4.872C11.24 4.872 10.608 4.272 9.24804 3.856L9.36004 3.728C9.36004 3.728 10.456 3.704 11.608 4.568C11.608 4.568 12.76 6.656 12.76 9.232C12.76 9.232 12.08 10.392 10.312 10.448Z"
        fill={color}
      />
    </svg>
  ),
  X: ({color = 'white', ...props}: SVGAttributes<SVGElement>) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 300 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z"
        fill={color}
      />
    </svg>
  ),
  LinkedIn: () => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9637 0H1.03359C0.462109 0 0 0.451172 0 1.00898V12.9883C0 13.5461 0.462109 14 1.03359 14H12.9637C13.5352 14 14 13.5461 14 12.991V1.00898C14 0.451172 13.5352 0 12.9637 0ZM4.15352 11.9301H2.07539V5.24726H4.15352V11.9301ZM3.11445 4.33672C2.44727 4.33672 1.90859 3.79805 1.90859 3.13359C1.90859 2.46914 2.44727 1.93047 3.11445 1.93047C3.77891 1.93047 4.31758 2.46914 4.31758 3.13359C4.31758 3.79531 3.77891 4.33672 3.11445 4.33672ZM11.9301 11.9301H9.85469V8.68164C9.85469 7.90781 9.84101 6.90977 8.77461 6.90977C7.69453 6.90977 7.53047 7.75469 7.53047 8.62695V11.9301H5.45781V5.24726H7.44844V6.16055H7.47578C7.75195 5.63555 8.43008 5.08047 9.43906 5.08047C11.5418 5.08047 11.9301 6.46406 11.9301 8.26328V11.9301Z"
        fill="white"
      />
    </svg>
  ),
  Docs: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.33317 1.33334H3.99984C3.64622 1.33334 3.30708 1.47382 3.05703 1.72387C2.80698 1.97392 2.6665 2.31305 2.6665 2.66668V13.3333C2.6665 13.687 2.80698 14.0261 3.05703 14.2762C3.30708 14.5262 3.64622 14.6667 3.99984 14.6667H11.9998C12.3535 14.6667 12.6926 14.5262 12.9426 14.2762C13.1927 14.0261 13.3332 13.687 13.3332 13.3333V5.33334L9.33317 1.33334Z"
        stroke="white"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 1.33334V5.33334H13.3335"
        stroke="white"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6668 8.66666H5.3335"
        stroke="white"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6668 11.3333H5.3335"
        stroke="white"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66683 6H6.00016H5.3335"
        stroke="white"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  GitHub: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_556_12797)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.47467 0C3.7884 0 0 3.66667 0 8.20283C0 11.8288 2.42736 14.8982 5.79474 15.9845C6.21575 16.0662 6.36997 15.808 6.36997 15.5908C6.36997 15.4007 6.35609 14.7488 6.35609 14.0697C3.99864 14.5587 3.50772 13.0918 3.50772 13.0918C3.12887 12.1412 2.56752 11.8968 2.56752 11.8968C1.79593 11.3943 2.62372 11.3943 2.62372 11.3943C3.47962 11.4487 3.92873 12.2363 3.92873 12.2363C4.68628 13.4857 5.90698 13.1327 6.39807 12.9153C6.46815 12.3857 6.6928 12.019 6.93132 11.8153C5.05108 11.6252 3.07284 10.919 3.07284 7.76817C3.07284 6.87183 3.40937 6.1385 3.94261 5.56817C3.85848 5.3645 3.56376 4.52233 4.02692 3.39517C4.02692 3.39517 4.74248 3.17783 6.35592 4.23717C7.04669 4.05761 7.75907 3.96627 8.47467 3.9655C9.19023 3.9655 9.91967 4.06067 10.5933 4.23717C12.2069 3.17783 12.9224 3.39517 12.9224 3.39517C13.3856 4.52233 13.0907 5.3645 13.0066 5.56817C13.5539 6.1385 13.8765 6.87183 13.8765 7.76817C13.8765 10.919 11.8983 11.6115 10.004 11.8153C10.3128 12.0733 10.5792 12.5622 10.5792 13.3363C10.5792 14.4363 10.5653 15.3192 10.5653 15.5907C10.5653 15.808 10.7197 16.0662 11.1406 15.9847C14.5079 14.898 16.9353 11.8288 16.9353 8.20283C16.9492 3.66667 13.1469 0 8.47467 0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_556_12797">
          <rect width="17" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
};
