import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import Marquee from 'react-fast-marquee';

export function SolanaBanner() {
  const [copiedText, copyToClipboard] = useCopyToClipboard(3000);
  const address = "tqrRt3AsuGa3aYYSvABMDrPJEWVzpAnh3p6LxXGpump";
  
  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-50 bg-black text-white h-8 text-sm font-mono">
        <Marquee speed={50} gradient={false} pauseOnHover>
          <button 
            onClick={() => copyToClipboard(address)}
            className="flex items-center gap-2 px-4 py-1.5 hover:text-gray-300 transition-colors cursor-pointer whitespace-nowrap"
          >
            🚀 ponzi market is launching on sol before christmas 🎅🏻 {address} {copiedText && "✓"}
          </button>
        </Marquee>
      </div>
      <div className="h-8" />
    </>
  );
}

